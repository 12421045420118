import { useContext, useState } from "react";
import LinkButton from "components/Buttons/LinkButton";
import Tasks from "./Tasks";
import { ParticipantContext } from "App";
import { STPTaskItem, STPTaskItemTaskStatus } from "types/api";

const SetupTasks = () => {
    const [display, setDisplay] = useState(true);
    const { participant } = useContext(ParticipantContext);
    const tasks = participant?.stpTasks;

    const allTasksCompleted = (tasks?: STPTaskItem[]) => {
        return tasks?.every(task => task.taskStatus === STPTaskItemTaskStatus.COMPLETED);
    }

    const dismiss = () => {
        if (!tasks || allTasksCompleted(tasks)) {
            setDisplay(false);
        }
    }

    if (!display) return null;

    return (
        <aside data-testid="setup-tasks" className="setup-tasks">
            <Tasks tasks={tasks} />
            <LinkButton
                disabled={!allTasksCompleted(tasks)}
                text="Dismiss quickstart"
                onClick={() => dismiss()}
            ></LinkButton>
        </aside>
    );
}

export default SetupTasks;