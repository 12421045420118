import { STPTaskItem } from "types/api";
import TaskItem from "./TaskItem";

interface TasksProps {
    tasks?: STPTaskItem[];
}

const Tasks = ({tasks}: TasksProps) => {
    
    return (
        <div className="tasks">
            <div className="tasks-title">
                <p>Welcome to Wrkr Pay!</p>
                <p>Quickstart guide</p>
            </div>
            <div className="tasks-content">
                {tasks?.map((task, index) => (
                    <TaskItem
                        key={index}
                        task={task}
                    />
                ))}
            </div>
        </div>
    );
}

export default Tasks;