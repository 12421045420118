import ProtectedRoutes from "./ProtectedRoutes";
import { Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Sidebar from "./components/Sidebar/Sidebar";
import { Route, Switch } from "react-router-dom";
import Forbidden from "./pages/Forbidden/Forbidden";
import TermsConditions from "./pages/AccountSetup/TermsConditions";
import { createContext, useCallback, useEffect, useState } from "react";
import ProtectedHeader from "./components/Header/ProtectedHeader";
import { isValidToken, getApplicationToken } from "./utils/token";
import history from "./utils/history";
import SpinnerModal from "./components/Spinner/SpinnerModal";
import { ToastContainer, Slide } from "react-toastify";
import { STPParticipantService } from "services/STPParticipantService";
import { ParticipantDetailsResponse } from "types/api";
import SetupTasks from "components/SetupTasks/SetupTasks";
import { FeatureToggle } from "feature-toggle/FeatureToggle";
import { Feature } from "feature-toggle/features";
import { REACT_APP_ENVIRONMENT } from "utils/configs";
import { getParameterByName } from "utils/string";

export interface ParticipantContextValues {
  participant?: ParticipantDetailsResponse;
  setParticipant: React.Dispatch<React.SetStateAction<ParticipantDetailsResponse | undefined>>;
  getParticipant: () => Promise<void>;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ParticipantContext = createContext({} as ParticipantContextValues);

const App = () => {
  const [participant, setParticipant] = useState<ParticipantDetailsResponse>();
  const accessToken = getParameterByName(window.location.href, "access_token");

  const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());
  const [isLoading, setIsLoading] = useState(false);

  const initializeData = {
    visitor: {
      id: participant?.stpUserDetails?.guid ?? "VISITOR-UNIQUE-ID",
      environment: REACT_APP_ENVIRONMENT,
    },
    account: {
      id: participant?.guid ?? "ACCOUNT-UNIQUE-ID",
    },
  };

  if (window.pendo) {
    window.pendo.initialize(initializeData);
  }

  const checkIfAuthenticated = useCallback(() => {
    setIsAuthenticated(isValidToken());
  }, []);

  const getParticipant = async () => {
    try {
      const res = await STPParticipantService.retrieveParticipant();
      const { data } = res;
      setParticipant(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated && accessToken) {
      setIsLoading(true);
      getApplicationToken(accessToken)
        .then(checkIfAuthenticated)
        .catch((error) => {
          console.error(error);
          setIsAuthenticated(false);
        })
        .finally(() => { setIsLoading(false); });
    }
    else {
      if (isAuthenticated) {
        getParticipant()
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [accessToken, isAuthenticated, checkIfAuthenticated]);

  return (
    <>
      <Router history={history}>
        <ParticipantContext.Provider value={{ participant, setParticipant, getParticipant, isAuthenticated, setIsAuthenticated, isLoading, setIsLoading }}>
          <ScrollToTop />
          <div className="page">
            {(isAuthenticated && participant) ? (!participant.termsAndConditionsAccepted
              ? <TermsConditions />
              : (
                <>
                  <ProtectedHeader />
                  <div className="page__content">
                    <Switch>
                      <Route exact path="/forbidden">
                        <Forbidden />
                      </Route>
                      <Route>
                        <Sidebar />
                        <main className="main">
                          <ProtectedRoutes />
                        </main>
                        <FeatureToggle feature={Feature.STP_REGISTRATION}>
                          <SetupTasks />
                        </FeatureToggle>
                      </Route>
                    </Switch>
                  </div>
                </>
              )) : (!isLoading && !isAuthenticated) ? <Forbidden /> : <SpinnerModal />}
          </div>
          <ToastContainer
            position="top-center"
            transition={Slide}
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
          />
        </ParticipantContext.Provider>
      </Router>
    </>
  );
};

export default App;
