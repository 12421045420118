import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import BMSIdForm from "./BMSIdForm";
import "react-tabs/style/react-tabs.css";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as ArrowLeftShort } from "bootstrap-icons/icons/arrow-left-short.svg";
import { retrieveStpDetails } from "types/api/services/stp/stp";
import { STPDetails, STPDetailsStpVersion } from "types/api";

interface STPVersionParams {
  stpVersion: STPDetailsStpVersion;
}

const BMSIdDetails = () => {
  const { stpVersion } = useParams<STPVersionParams>();
  const [selectedStpVersion, setSelectedStpVersion] = useState(stpVersion);
  const [tabIndex, setTabIndex] = useState(stpVersion === STPDetailsStpVersion.STP1 ? 0 : 1);
  const [stpDetails, setStpDetails] = useState<STPDetails>({} as STPDetails);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        setSelectedStpVersion(STPDetailsStpVersion.STP1);
        break;
      case 1:
        setSelectedStpVersion(STPDetailsStpVersion.STP2);
        break;
      default:
        setSelectedStpVersion(STPDetailsStpVersion.STP1);
    }
  }, [tabIndex]);

  useEffect(() => {
    const getStpDetails = async () => {
      setIsLoading(true);
      try {
        const response = await retrieveStpDetails(selectedStpVersion);
        setStpDetails(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedStpVersion) {
      getStpDetails();
    } else {
      setIsLoading(false);
    }
  }, [selectedStpVersion]);

  return (
    <>
      {/* <Breadcrumbs /> */}
      <div className="back-container mb-6">
        <Link to="/settings">
          <ArrowLeftShort />
          <span>Back to Settings</span>
        </Link>
      </div>
      <h1 className="mb-8">STP details</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <Tabs selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)}>
          <TabList>
            <Tab selectedClassName="is-active">STP1</Tab>
            <Tab selectedClassName="is-active">STP2</Tab>
          </TabList>
          <TabPanel>
            <BMSIdForm stpDetails={stpDetails} />
          </TabPanel>
          <TabPanel>
            <BMSIdForm stpDetails={stpDetails} />
          </TabPanel>
        </Tabs>
      )}
    </>
  );
};

export default BMSIdDetails;
