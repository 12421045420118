/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * STP API
 * STP API Description
 * OpenAPI spec version: 1.0
 */
import type {
  UploadCompleteRequest,
  STPDetailResponse,
  STPDetailLodgeRequest,
  STPDetailsSearchResponse,
  STPDetailsSearchRequest,
  STPDetails,
  ParticipantResponse,
  ParticipantRequest,
  ParticipantDetailsResponse,
  PreSignedUrlResponse,
  RetrievePreSignedUrlParams,
  DeclarationResponse,
} from "../..";
import updateStepMutator from "../../../../utils/HttpClientOrval";
import updateMutator from "../../../../utils/HttpClientOrval";
import submitMutator from "../../../../utils/HttpClientOrval";
import lodgeXmlMutator from "../../../../utils/HttpClientOrval";
import cancelMutator from "../../../../utils/HttpClientOrval";
import searchStpHistoryMutator from "../../../../utils/HttpClientOrval";
import verifyOrUpdateStpDetailsMutator from "../../../../utils/HttpClientOrval";
import createOrUpdateParticipantMutator from "../../../../utils/HttpClientOrval";
import acceptTermsAndConditionsMutator from "../../../../utils/HttpClientOrval";
import retrieveSTPDetailsMutator from "../../../../utils/HttpClientOrval";
import validateXmlMutator from "../../../../utils/HttpClientOrval";
import retrievePreSignedUrlMutator from "../../../../utils/HttpClientOrval";
import downloadSTPCsvReportMutator from "../../../../utils/HttpClientOrval";
import retrieveParticipantMutator from "../../../../utils/HttpClientOrval";
import retrieveStpDetailsMutator from "../../../../utils/HttpClientOrval";
import getDeclarationContentMutator from "../../../../utils/HttpClientOrval";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * This should be called to update the current step of wizard
 * @summary Update current step
 */
export const updateStep = (
  processId: string,
  step: number,
  options?: SecondParameter<typeof updateStepMutator>,
) => {
  return updateStepMutator<void>(
    { url: `/stp-details/${processId}/step/${step}`, method: "put" },
    options,
  );
};
/**
 * Send response to update uploaded file
 * @summary Send response to update uploaded file
 */
export const update = (
  processId: string,
  uploadCompleteRequest: UploadCompleteRequest,
  options?: SecondParameter<typeof updateMutator>,
) => {
  return updateMutator<string>(
    {
      url: `/stp-details/upload/xml/${processId}/update`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: uploadCompleteRequest,
    },
    options,
  );
};
/**
 * Send response to submit uploaded file
 * @summary Send response to submit uploaded file
 */
export const submit = (
  processId: string,
  uploadCompleteRequest: UploadCompleteRequest,
  options?: SecondParameter<typeof submitMutator>,
) => {
  return submitMutator<string>(
    {
      url: `/stp-details/upload/xml/${processId}/submit`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: uploadCompleteRequest,
    },
    options,
  );
};
/**
 * This should be called after the xml file is uploaded and checked the status of validation result through validate end point. The declaration type is returned in the validate end point, must submit in the response body with accepting username.
 * @summary Submit already uploaded and validated xml file to ATO
 */
export const lodgeXml = (
  processId: string,
  sTPDetailLodgeRequest: STPDetailLodgeRequest,
  options?: SecondParameter<typeof lodgeXmlMutator>,
) => {
  return lodgeXmlMutator<STPDetailResponse>(
    {
      url: `/stp-details/lodge/xml/${processId}`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: sTPDetailLodgeRequest,
    },
    options,
  );
};
/**
 * This should be called before submitting to ATO
 * @summary Cancel submitted XML
 */
export const cancel = (
  processId: string,
  options?: SecondParameter<typeof cancelMutator>,
) => {
  return cancelMutator<string>(
    { url: `/stp-details/cancel/${processId}`, method: "post" },
    options,
  );
};
/**
 * This is called to retrieve the stp history with pagination data
 * @summary Retrieve stp history with pagination
 */
export const searchStpHistory = (
  sTPDetailsSearchRequest: STPDetailsSearchRequest,
  options?: SecondParameter<typeof searchStpHistoryMutator>,
) => {
  return searchStpHistoryMutator<STPDetailsSearchResponse>(
    {
      url: `/search/stp-history`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: sTPDetailsSearchRequest,
    },
    options,
  );
};
/**
 * A participant verify or update stp details
 * @summary A participant verify or update stp details
 */
export const verifyOrUpdateStpDetails = (
  sTPDetails: STPDetails,
  options?: SecondParameter<typeof verifyOrUpdateStpDetailsMutator>,
) => {
  return verifyOrUpdateStpDetailsMutator<STPDetails>(
    {
      url: `/participant/stp-details/update-and-verify`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: sTPDetails,
    },
    options,
  );
};
/**
 * This is called to Create or update participant
 * @summary Create or update participant
 */
export const createOrUpdateParticipant = (
  participantRequest: ParticipantRequest,
  options?: SecondParameter<typeof createOrUpdateParticipantMutator>,
) => {
  return createOrUpdateParticipantMutator<ParticipantResponse>(
    {
      url: `/participant/create-or-update`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: participantRequest,
    },
    options,
  );
};
/**
 * A participant to accept terms and conditions
 * @summary A participant to accept terms and conditions
 */
export const acceptTermsAndConditions = (
  options?: SecondParameter<typeof acceptTermsAndConditionsMutator>,
) => {
  return acceptTermsAndConditionsMutator<ParticipantDetailsResponse>(
    { url: `/participant/accept-terms-and-conditions`, method: "post" },
    options,
  );
};
/**
 * Retrieve stp details using process id and employer ABN
 * @summary Retrieve STP details
 */
export const retrieveSTPDetails = (
  processId: string,
  options?: SecondParameter<typeof retrieveSTPDetailsMutator>,
) => {
  return retrieveSTPDetailsMutator<STPDetailResponse>(
    { url: `/stp-details/${processId}`, method: "get" },
    options,
  );
};
/**
 * This should be called after the xml file is uploaded. As large messages may take some time to process, this endpoint should be polled periodically until a successful response is received
 * @summary Retrieve validation results for uploaded xml file
 */
export const validateXml = (
  processId: string,
  options?: SecondParameter<typeof validateXmlMutator>,
) => {
  return validateXmlMutator<STPDetailResponse>(
    { url: `/stp-details/validate/xml/${processId}`, method: "get" },
    options,
  );
};
/**
 * Retrieve pre-signed S3 url to upload files
 * @summary Retrieve pre-signed S3 url to upload files
 */
export const retrievePreSignedUrl = (
  params: RetrievePreSignedUrlParams,
  options?: SecondParameter<typeof retrievePreSignedUrlMutator>,
) => {
  return retrievePreSignedUrlMutator<PreSignedUrlResponse>(
    { url: `/stp-details/get/pre-signed-urls`, method: "get", params },
    options,
  );
};
/**
 * Download Error STP details CSV report using process id and employer ABN
 * @summary Download Error STP details CSV report
 */
export const downloadSTPCsvReport = (
  processId: string,
  options?: SecondParameter<typeof downloadSTPCsvReportMutator>,
) => {
  return downloadSTPCsvReportMutator<string>(
    { url: `/stp-details/download/${processId}`, method: "get" },
    options,
  );
};
/**
 * This is called to retrieve participant
 * @summary Retrieve participant
 */
export const retrieveParticipant = (
  options?: SecondParameter<typeof retrieveParticipantMutator>,
) => {
  return retrieveParticipantMutator<ParticipantDetailsResponse>(
    { url: `/participant`, method: "get" },
    options,
  );
};
/**
 * This is called to retrieve participant stp details
 * @summary Retrieve participant stp details
 */
export const retrieveStpDetails = (
  stpVersion: "STP1" | "STP2",
  options?: SecondParameter<typeof retrieveStpDetailsMutator>,
) => {
  return retrieveStpDetailsMutator<STPDetails>(
    { url: `/participant/stp-details/${stpVersion}`, method: "get" },
    options,
  );
};
/**
 * Returns declaration content in html format for the given declaration type.
 * @summary Retrieve declaration content
 */
export const getDeclarationContent = (
  declarationType: "ESSP" | "EMSSP" | "RASSP" | "RAMSSP",
  options?: SecondParameter<typeof getDeclarationContentMutator>,
) => {
  return getDeclarationContentMutator<DeclarationResponse>(
    { url: `/declaration/${declarationType}`, method: "get" },
    options,
  );
};
