import Cookies from 'universal-cookie';
import { API_HOST } from './configs';
import axios from "axios";
import jwt_decode from "jwt-decode";
import history from "./history.js";

export const getApplicationToken = async (accessToken: string) => {
  try {
    const response = await axios.post(
      `${API_HOST}/token/validate/legacy-token`,
      accessToken
    );

    const decoded = jwt_decode<any>(response.data);
    const { expMin } = decoded;
    const cookies = new Cookies();
    cookies.set('token', response.data, { 
      expires: new Date(Date.now() + Number(expMin) * 60 * 1000)
    });
    history.push("/");
    return decoded;
  } catch (error) {
    console.log("Error -", error);
    history.push("/forbidden");
  }
};

export const isValidToken = () => {
  if (!!getToken()) {
    return true;
  } else {
    resetSessionStorage();
    return false;
  }
};

export const resetSessionStorage = () => {
  const cookies = new Cookies();
  cookies.remove('token');
};

export const getToken = (): string | null => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return token || null;
}

