import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken, isValidToken } from "./token";
import { API_HOST } from "./configs";
import history from "./history.js";

const HttpClient = axios.create();

HttpClient.interceptors.request.use(
    async (config: any) => {
        try {
            let token = getToken();
            if (isValidToken()) {
                config.headers.Authorization = token;
                config.baseURL = `${API_HOST}/stp/`;
                return config;
            } else {
                history.push("/forbidden");
            }
        } catch {
            throw new axios.Cancel("Operation canceled by the user.");
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default async function customInstance<T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> {
    return await HttpClient({ ...config, ...options });
}
